table {
  width: 100%;
  border: solid 1px white;
  white-space: nowrap;
}
thead {
  background-color: rgba(0, 0, 0, 0.2);
  color: rgb(255 255 255 / 80%);
  white-space: nowrap;
}
th {
  padding: 0.7rem;
  font-size: 1rem;
  max-width: 300px;
  text-align: center !important;
}
td {
  padding: 0.5rem;
  color:#252121;
  font-size: 1.1rem;
}

.table_main td{
  padding: 0.5rem;
  color:white !important;
  font-size: 1.1rem;
}
tr {
  border: 1px solid #797c13 !important;
}

.table_main {
  /* height: 100% !important; */
  overflow-x: scroll;
}
.table_main::-webkit-scrollbar {
  width: 0px;
}
.table_tr {
  line-height: 2.5rem !important;
}


.result4 {
  width: 37%;
  padding: 1px 7px;
  background-color: green;
  border-radius: 25px;
  font-size: 17px;
}

.result3 {
  width: 37%;
  padding: 1px 7px;
  background-color: red;
  border-radius: 25px;
  font-size: 17px;
}
