.ab-2-container {
    width: 100%;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    background-color: #ddd;
    color: #000;
    font-size: 14px;
}

.bet-a {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: 2%;
    margin-bottom: 0;
    /* perspective: 45rem; */
}
.mains{
    margin-top: 20px;
    background: #202020;
    padding: 15px 0px;
    border-radius: 10px;
}
.ab-2-box {
    background: #202020;
    color: #fff;
    padding: 5px;
}
.book{
    color: #fff !important;
    text-align: center;
}
.ab-2 .blbox {
    padding: 8px 0;
    font-weight: bold;
    font-size: 18px;
    color: #000;
}
.bltitle img {
    height: 23px;
}
.card-image {
    display: inline-block;
}

.m-r-5 {
    margin-right: 5px;
}
.card-image img {
    width: 40px;
}
.a-title {
    width: 10%;
    text-align: center;
    padding: 5px 0;
    padding-top: 15px;
    /* transform: rotateX(45deg); */
    font-size: 20px;
}
.sa {
    width: 20%;
    margin-left: 2%;
}

.sa > div:first-child {
    text-align: center;
    padding: 5px 0;
    border: 3px solid yellow;
    border-radius: 8px;
    /* transform: rotateX(45deg); */
}
.second-bet > div:first-child {
    text-align: center;
    padding: 5px 0;
    border: 3px solid yellow;
    border-radius: 8px;
    /* transform: rotateX(45deg); */
    background-color: #086cb8;
    color: #fff;
}

.second-bet {
    margin-right: 0;
}
.first-bet, .second-bet {
    width: 25%;
    margin: 0 2%;
}
.first-bet, .second-bet {
    width: 25%;
    margin: 0 2%;
}

.ab-2-container .book {
    text-align: center;
    margin-top: 5px;
}

.first-bet > div:first-child, .second-bet > div:first-child {
    text-align: center;
    padding: 5px 0;
    border: 3px solid yellow;
    border-radius: 8px;
    /* transform: rotateX(45deg); */
    background-color: #086cb8;
    color: #fff;
}
