.funds-transfer-main-div{
    background-color: rgb(20, 19, 19);
    min-height: 86vh;
    width: 100%;
    max-height: auto;
    border-radius: 5px;

}
.F-h{
    font-weight: 700;
    color: white ;
    font-size: 20px;
    
    
}
.form-area{
    border-top: 1px solid rgb(66, 61, 61);
}
.deposit-card{
     height: 467px;
    /* height: auto; */
    /* width: 500px; */
    background-color: black;
    border: 1px solid rgb(99, 96, 96);
    color: white;
    overflow: auto;
}
.balance-div{
    
}
.inst{
    color: white;
    font-size: 14px;
}
.step-1{
    color:#fa5004;
    font-size: 17px;
    font-weight: 600;
    font-family: "mukta";
}
label{
    font-size: 11px;

}
/* .user-id-input{
    background-color:rgb(102, 146, 146);
} */

.deposit-f-btn{
    width: 100%;
    background-color: #fa5004;
    color: white;
}
.deposit-f-btn:hover{
    background-color: #fa5004 !important;
}
@media screen and (max-width: 600px){
    .deposit-card{
        /* height: 500px;
        width: 340px; */
        background-color: black;
        border: 1px solid rgb(99, 96, 96);
        color: white;
    }
    .inst{
        color: white;
        font-size: 9px;
    }
    .step-1{
        color:#fa5004;
        font-size: 9px;
        font-weight: 600;
        font-family: "mukta";
    }
    .deposit-btn{
        width: 100%;
        background-color: #fa5004;
        color: white;
    }


}

@media screen and (max-width: 490px){
    .deposit-card{
        /* height: 500px; */
        /* width: 340px; */
        background-color: black;
        border: 1px solid rgb(99, 96, 96);
        color: white;
    }
    .inst{
        color: white;
        font-size: 12px;
    }
    .step-1{
        color:#fa5004;
        font-size: 13px;
        font-weight: 600;
        font-family: "mukta";
    }


}


/* |+++++++++++++++++++++++++++++++++++++++++++++++++=========================== */


/* .table-responsive{
    height: 110rem;
} */


.button_value{
    width: 100%;
}
.update{
margin-left: 25%;
}

@media (min-width:600px) {
    .mod_12{
        display: flex;
    }
    .password{
        width: 70%;
    }
}

@media (max-width:600px) {
    
    .password{
        width: 100%;
    }
}


.submit_BTN{
    background-color: #ffc107;
    color:#fff;
    font-weight: 600;
    font-size: 16px;
}

.submit_BTN:hover{
    background-color: #ffc107;
    color: #fff;
}

.buy_header_h {
    color: #fff;
    font-size: 2rem;
    line-height: 1.5;
    margin-bottom: 0;
}

.buy_main {
    padding: 40px 0px;
}

.buy_header_h {
    margin-bottom: 0;
    line-height: 1.5;
    /* margin-left: 24px; */
    color: #fff;
    font-size: 2rem;
}


.buy_card {
    background: #000000 !important;
    /* box-shadow: 0 0 1rem #ffffff; */
    border: 1px solid #fa5004;
    /* border-radius: 25px; */
    min-height: 612px;
}

.buy_hea {
    padding: 14px 15px !important;
    border-bottom: 1px solid #fa5004;
}

.card_in {
    padding: 2px 10px 2px 10px !important;
    width: 69% !important;
    border:2px solid #fa5004;
    border-radius: 9px;
}

.input_buy {
    display: flex;
    justify-content: left;
}

.input_btn {
    border: 1px solid  #fa5004;
    background:  #fa5004;
    color: #fff !important;
    padding: 10px 30px !important;
    /* margin: 5px; */
    cursor: pointer;
    border-radius: 5px;
}


.input_btn:hover {
    background-color:  #077fff !important;
}

.buy_token {
    background:  #000000;
    border: 1px solid  #fa5004;
    box-shadow: 4px 2px 6px rgb(0 0 0 / 51%), inset -2px -2px 6px rgb(0 0 0 / 34%);
    padding: 15px;
    /* height: 140px; */
    width: 30%;
text-align: center;
    border-radius: 10px;
    /* border: 1px solid transparent; */
}



@media only screen and (max-width:600px) {

    .input_btn {
        padding: 5px 10px !important;
    }
}

.spinnerload{
    color: #fff !important;
    margin-top: 0.5rem;
    padding: 30px;
}


.loader {
    /* content: ""; */
    position: absolute !important;
    width: 200px;
    height: 200px;
    border-radius:40% ;
    background: #111;
    /* top: -150%;
    left: -50%; */
    opacity: 0.6;
    /* animation: animate 12s linear infinite; */
    

}

.buy_coin_history{
    height: 50vh;
}



#fancy {
    background-color: #f37a13 !important;
    color: #fff;
    text-align: center;
}


.btn1{
    padding: 0.375rem 1.5rem;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
}


.btn1-warning{
    color:#fff;
    background-color: #ffc107;
    border: 1px solid #fff;
}