.deposit-detail-btn{
    border: 1px solid rgb(97, 207, 54);
    width: 150px;
    border-radius: 20px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

}
.deposit-detail-btn span{
    color: red;
    font-family: "mukta";
    font-size: 19px;
}
.withdrawal-detail-btn{
    border: 1px solid rgb(97, 207, 54);
    width: 170px;
    border-radius: 20px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

}
.withdrawal-detail-btn span{
    color: red;
    font-family: "mukta";
    font-size: 19px;
}
.date-input{
    width: 50px;
}