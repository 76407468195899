.casinocard-div{
    width: 180px;
    background-color: #202020;
    border-radius: 5px;
    border:none;
    
  }
  .small-card-img{
    border-radius: 5px;
  }
  .live-casino-main-div{
    height: 88vh;

  }
  .overlaySmallCard{
    position:absolute;
    bottom:0;
    overflow: hidden;
    left:0;
    right:0;
    height:0;
    background-color: rgba(58, 54, 54, 0.75);
    border-radius: 5px;

  
}
.overlaySmallCard p{
    color: #fa5004;
    font: "mukta";
    font-size: 14px;
    font-weight: 600;
    /* margin-left: 36px; */
    margin-top: 36px;
}

.casinocard-div:hover{
    
    /* translate: 0px 15px 0px; */
    transition: .5s ease;
}
.casinocard-div:hover .overlaySmallCard{
    height:100%;
    width:100%;
    border-radius: 5px;


}
@media screen and (max-width: 550px){
    .casinocard-div{
        width: 180px;
        background-color: #202020;
        border-radius: 5px;
        border:none;
       
    }


}
@media screen and (max-width: 450px){
    .casinocard-div{
        width: 273px;
        max-width: 100%;
        background-color: #202020;
        border-radius: 5px;
        border: none;
       
    }

    .he{
        overflow: scroll;
        height: 504px;
    }
}


