
.background-img {
  background: url(../../Media/login_bg4.jpg);
  background-size:auto;
  width: 100vw;
  height: 100vh;
  position: absolute;
  /* background-size: 100% 100%; */
  /* background-repeat: no-repeat; */
  /* position: relative; */
  z-index:99;
  overflow: hidden;
}
.img-div{
    height: 80px;
    width: 80px;

}


.login-card{
    height: 370px;
    width: 400px;
    background-color: rgb(17, 16, 16);
    position: absolute;
    z-index:99999;
    top: 15%;

}
.login-card form{
    color: white;
}
.login-input{
    outline: none !important;
    border: none !important;
    background-color:rgb(29, 25, 25) ;
    height: 35px;
    width: 100%;
    border-radius: 5px;
    color: white !important;

}
.login-btn{
    width: 100%;
    background-color: #009B2f;
    color: white;
    font-family: "mukta";
}
.login-btn:hover{
    background-color:#fa5004 ;
}
.bth{
    color: white;
    font-size: 9px;
}
.bth span{
    color:#fa5004;
    
}
